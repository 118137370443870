<template>
  <main class="error 404">
    <h1>404</h1><h1>Page Not Found</h1>
    <section>
        Maybe try the <router-link to="/">home page</router-link>?
    </section>
  </main>
</template>

<script>
export default {
  name: 'Error404'
}
</script>

<style scoped>

</style>
